import { firebaseKeys } from './firebase.config';
import {MediaMatcher} from '@angular/cdk/layout';
import {ChangeDetectorRef, OnDestroy} from '@angular/core';
import * as firebase from 'firebase';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { House } from './shared/core/interfaces';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { getHousePreferenceKey } from './shared/core/utils';
import { Router } from '@angular/router';
import { HouseSelectedService } from "./shared/services/houseselected.service";

import { AuthService, AlertService } from './shared/services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {

  isSignedIn: boolean = false;
  public ngOnInit(): void {
    //firebase.initializeApp(firebaseKeys);
    this.afAuth.authState.subscribe(res => {

      if (res && res.uid) {
        this.uid = res.uid;
        this.isSignedIn = true;

        this.loadHome()
        this.data.selectedHouse.subscribe(selectedHouse => this.updateSelected())
      } else {
        this.isSignedIn = false;

        console.log("logged Out");
      }
    });
  }

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({length: 50}, (_, i) => `Nav Item ${i + 1}`);

  fillerContent = Array.from({length: 50}, () =>
      `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
       labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
       laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
       voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
       cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`);

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, public authService: AuthService,
    private alertService: AlertService,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private data: HouseSelectedService) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    console.log("constructed")
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));






























  public isAuthenticated: string;
  public angularImage: string = "assets/images/cube_icon_small.png";
  houses: House[];
  housesEditing: House[];

  selected: House;
  selectedHouse:string;
  uid:string;
  public menuItems: Object[] = [

  ];

  


  async loadHome() {
    console.log("load home");
    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        console.log("load home logged in");

        this.loadHousesList();
  
      } else {
        console.log("logged Out");
      }
    });
  }

  loadHousesList(){
    this.afs.collection<House>('Houses', ref => ref.where('Owners', 'array-contains', this.uid)).snapshotChanges().subscribe(actionArray => {
      this.houses = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as House
      })
    
      for (let i in this.houses) {
        this.afs.collection('Houses/' + this.houses[i].id + '/PrivateData').doc("HouseData").get().subscribe(data=>{
          this.houses[i].name = data.data().name;
       });

       if(this.houses[i].id == localStorage.getItem(getHousePreferenceKey(this.uid))){
        this.selected = this.houses[i];
       }

      }
      console.log("loaded houses");
      console.log(this.houses);



    });

    this.afs.collection<House>('Houses', ref => ref.where('Editors', 'array-contains', this.uid)).snapshotChanges().subscribe(actionArray => {
      this.housesEditing = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as House
      })
    
      for (let i in this.houses) {
        this.afs.collection('Houses/' + this.housesEditing[i].id + '/PrivateData').doc("HouseData").get().subscribe(data=>{
          this.housesEditing[i].name = data.data().name;
       });

       if(this.housesEditing[i].id == localStorage.getItem(getHousePreferenceKey(this.uid))){
        this.selected = this.housesEditing[i];
       }

      }
      console.log("loaded houses");
      console.log(this.housesEditing);



    });
    


  }

  updateSelected(){
    for (let i in this.houses) {

     if(this.houses[i].id == localStorage.getItem(getHousePreferenceKey(this.uid))){
      this.selected = this.houses[i];

     }

    }
  }

  handleSelection($event){
    if(localStorage.getItem(getHousePreferenceKey(this.uid)) != $event.value.id){
      localStorage.setItem(getHousePreferenceKey(this.uid), $event.value.id);
      this.router.navigate(['/home', { id: $event.value.id }]);

    }
  }

  public userUid(): string {
    return firebase.auth().currentUser.uid;
  }

  public userEmail(): string {
    return firebase.auth().currentUser.email;
  }

  public userName(): string {
    return firebase.auth().currentUser.displayName;
  }

  public onLogout(): void {
    this.alertService.showToaster('Logout successful');
    return this.authService.logout();
  }

}
