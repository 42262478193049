import { Component } from '@angular/core';

@Component({
  selector: 'app-about-me',
  templateUrl: './about-me.component.html',
  styles: [`
    #content {
      padding: 20px;
    }
  `]
})
export class AboutMeComponent {}
