import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class HouseSelectedService {

  private selectedHouseSource = new BehaviorSubject('default message');
  selectedHouse = this.selectedHouseSource.asObservable();

  constructor() { }

  changeSelectedHouse(selectedHouse: string) {
    this.selectedHouseSource.next(selectedHouse)
  }

}