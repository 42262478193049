import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Storage } from '../core/interfaces';
import { AlreadySelectedImage } from '../../shared/core/interfaces';
import { PreviewImage } from '../../shared/core/interfaces';
import { ResizedFile } from '../../shared/core/interfaces';
import { getHousePreferenceKey, getFormattedTime, getCompressionSizeForContainer, getRatioSizeForContainer, dataURItoBlob} from '../core/utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';

export interface StorageDialogData {
  editing: boolean;
  container: Storage;
  insideOfId;
  uid:string;
  houseId:string;
}

@Component({
  selector: 'app-addcontainerdialog',
  templateUrl: './addcontainerdialog.component.html',
  styleUrls: ['./addcontainerdialog.component.scss']
})
export class AddcontainerdialogComponent {

  containersCollectionRef: AngularFirestoreCollection<Storage>;
  originalContainerImages = [];
  previewImages: PreviewImage[] = [];
  containerNameControl = new FormControl("", Validators.required);
  containerValueControl = new FormControl();
  title;
  containerForm;
  loading = false;
  constructor(public sanitizer: DomSanitizer, private ng2ImgMaxService: Ng2ImgMaxService, private afs: AngularFirestore, private router: Router, private storage: AngularFireStorage, public afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<AddcontainerdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: StorageDialogData) {


      this.containerForm = new FormGroup({
        containername: this.containerNameControl,
        containervalue: this.containerValueControl
       }); 


    if(data.editing){
      this.title = "Edit Container"
    for (let selectedUrl of data.container.imageUrls) {
      this.originalContainerImages.push(new AlreadySelectedImage(selectedUrl));
    }
    this.setForm(data);

  }else{
    this.title = "Add Container"

  }
  
  var houseId = localStorage.getItem(getHousePreferenceKey(this.data.uid));

    for (let originalContainerImage of this.originalContainerImages) {
        const fullRef = 'Houses/' + houseId + '/Storage/' + originalContainerImage.imageName;
        const ref = this.storage.ref(fullRef);
        ref.getDownloadURL().subscribe(url=>{
          if(url){
            originalContainerImage.fullUrl = url;
          }
       })
       
  }

    }

    setForm(data){
      const container = data.container;
      this.containerNameControl.setValue(container.name);
      if(container.price != null && container.price != ""){
        this.containerValueControl.setValue(Number(container.price))
      }
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public addContainer(form: NgForm) {
    this.loading = true;
    if(this.previewImages.length>0){

           const pathToContainersImages = 'Houses/' + localStorage.getItem(getHousePreferenceKey(this.data.uid)) + '/Storage/';  

            var promises = [];
            for (let previewImage of this.previewImages) {
              var location = `${pathToContainersImages}${previewImage.file.name}`;

            promises.push(this.storage.upload(location, previewImage.file));
            }

            Promise.all(promises).then(tasks => {
            
              this.saveContainerData(this.previewImages)
              }).catch(err => console.log("Error uploading files"));

    }else{
      this.saveContainerData(null);
    }
  
}

deleteOriginalContainer(index){
  this.originalContainerImages.splice(index,1);
}

deletePreviewImage(index){
  this.previewImages.splice(index, 1);
}

saveContainerData(resizedFiles){
const container = new Storage()

if(this.data.editing){
  container.insideOfId = this.data.container.insideOfId;
}else{
  container.insideOfId = this.data.insideOfId;
}

container.name= this.containerNameControl.value;

if(this.containerValueControl.value != null){
  console.log(this.containerValueControl.value)
container.price= this.containerValueControl.value.toFixed(2).toString();
}

var imagesArray = [];
if(this.originalContainerImages != null){
  for (let originalContainerImage of this.originalContainerImages) {
    imagesArray.push(originalContainerImage.imageName);
  }
}

if(resizedFiles != null){
  for (let resizedFile of resizedFiles) {
    imagesArray.push(resizedFile.name);
    }
}


container.imageUrls = imagesArray;


this.containersCollectionRef = this.afs.collection<Storage>('Houses/' + localStorage.getItem(getHousePreferenceKey(this.data.uid)) + '/Storage');

if(this.data.editing){
  console.log(this.data.container.id);
  this.containersCollectionRef.doc(this.data.container.id).update({...container}).then(response1 => {
    this.dialogRef.close();
  })

  
}else{

this.containersCollectionRef.add({...container}).then(response1 => {
  this.dialogRef.close();
  this.navigateToContainer(response1.id)
})

}
}

navigateToContainer(containerId) {
this.router.navigate(['/container', { houseId: this.data.houseId, id: containerId }]);
}


onSelect(event) {
  if(this.hasAnImageAlreadySelected()){
    this.originalContainerImages = [];
    this.previewImages = []
  }

  this.ng2ImgMaxService.compressImage(event.addedFiles[0], .25, true).subscribe((result)=>{ //todo sizing

    const imageFile = new File([result], getFormattedTime());
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      this.previewImages.push(new PreviewImage(imageFile, imageFile.name, reader.result));
    };

  },
  error => {
    console.log('Error compressing image', error);
  });
}



hasAnImageAlreadySelected(){
  return this.originalContainerImages.length>0 || this.previewImages.length>0;
}

}


