import { Injectable } from '@angular/core';
import { Subject } from 'rxjs'
import { getHousePreferenceKey } from '../../shared/core/utils';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Storage } from '../../shared/core/interfaces';
import { Room } from '../../shared/core/interfaces';

@Injectable()
export class InsideofService {


    private insideOfStringSource = new Subject<string>();
    insideOfString = this.insideOfStringSource.asObservable();
    storageCollectionRef;
    insideOfFullString = [];
    currentSubscriptions = [];

    constructor(private afs: AngularFirestore, public afAuth: AngularFireAuth){}
    uid:string;
    getInsideOfString(id: string, uid: string){
        this.uid = uid;
        console.log("getting inside");
        for (let currentSubscription of this.currentSubscriptions) {
            currentSubscription.unsubscribe();
        }
        this.currentSubscriptions = [];
        this.insideOfFullString = [];
        this.loopThroughInsideOf(id);
    }

    loopThroughInsideOf(id){
        var houseId = localStorage.getItem(getHousePreferenceKey(this.uid));

        this.currentSubscriptions.push(this.afs.collection('Houses/' + houseId + '/Storage').doc<Storage>(id).get().subscribe(response=>{

            if(response.exists){
                this.insideOfFullString.push(response.data().name)
                console.log(this.insideOfFullString);
                this.loopThroughInsideOf(response.data().insideOfId)
            } else {
                this.getInsideOfRoom(id);
            }
         }));
    }

    getInsideOfRoom(id) {
        var houseId = localStorage.getItem(getHousePreferenceKey(this.uid));

        console.log(houseId);
        console.log(id);

        this.currentSubscriptions.push(this.afs.collection('Houses/' + houseId + '/Rooms').doc<Room>(id).get().subscribe(response=>{
            
            this.insideOfFullString.push(response.data().name);
            console.log(this.insideOfFullString);

            this.insideOfStringSource.next(this.insideOfFullString.reverse().join(" > ").toString() + " >")
         }));
         console.log(this.currentSubscriptions);
    }

    // private fun getParentUntilRoom(insideOfId: String) {
    //     val db = NewFirestoreUtil(sharedPreferences).getStorageCollectionReference(mAuth).whereEqualTo(FieldPath.documentId(), insideOfId)


    //     db.get().addOnCompleteListener { task ->

    //         if (task.isSuccessful) {

    //             val myList = arrayListOf<Storage>()

    //             for (document in task.result!!) {
    //                 val tempStorage = document.toObject(Storage::class.java)
    //                 tempStorage.id = document.id
    //                 myList.add(tempStorage)
    //             }

    //             if (myList.size > 0) {
    //                 insideOfList.add(myList[0])

    //                 if(!myList[0].imageUrls.isNullOrEmpty()){
    //                     getNewInsideOfImage(NewFirestoreUtil(sharedPreferences).getStorageImageReference(myList[0].imageUrls[0]))
    //                 }else if(myList[0].imageUrl !=null) {
    //                     getInsideOfImage(myList[0].imageUrl!!)
    //                 }

    //                 locationOfStorage = myList[0].name + " > " + locationOfStorage

    //                 getParentUntilRoom(myList[0].insideOfId!!)
    //             } else {
    //                 getParentRoom(insideOfId)
    //             }

    //         } else {
    //             handleError()
    //         }
    //     }

    // }


}