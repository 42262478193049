import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
// import { flyInOut } from '../../router.animations';
import { getHousePreferenceKey } from '../../shared/core/utils';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { Room } from '../../shared/core/interfaces';
import { House } from '../../shared/core/interfaces';
import { Item } from '../../shared/core/interfaces';
import { ActivatedRoute, Params } from '@angular/router';
import { IntroDialog } from './../../pages/home/intro-dialog.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireFunctions } from '@angular/fire/functions';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import { AddhomedialogComponent } from '../../shared/addhomedialog/addhomedialog.component';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { HouseSelectedService } from "../../shared/services/houseselected.service";

class ImageSnippet { 
  pending: boolean = false;
  status: string = 'init';

  constructor(public src: string, public file: File) {}
}

class RoomPlusImage {
  displayImage: string = 'assets/images/empty_room.png'
  constructor(public room: Room) {}

}



@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
  //animations: [flyInOut()]
})
export class HomeComponent implements OnInit {


  selectedHouse:string;
  uid:string;
  displayName:string;
  myControl = new FormControl()
  filteredOptions: Observable<Item[]>
  displayFn(subject) {
      return subject ? subject.name : undefined;
  }

  roomsCollectionRef: AngularFirestoreCollection<Room>;
  housesCollectionRef: AngularFirestoreCollection<House>;
  housesListCollectionRef: AngularFirestoreCollection<House>;

  roomsPlusImages: RoomPlusImage[];
  itemsCollectionRef: AngularFirestoreCollection<Item>;
  public remoteData: Item[];

  rooms: Room[];
  houses: House[];
  selected: House;
  public roomNameField;
  fb;
  selectedFile: ImageSnippet;
  animal: string;
  name: string;
  loading = true;

  constructor(private houseSelectedService: HouseSelectedService, private route: ActivatedRoute, public dialog: MatDialog, public afAuth: AngularFireAuth, private router: Router, private afs: AngularFirestore, private storage: AngularFireStorage, private fireFunctions: AngularFireFunctions) { 



  }

  paramsChange(id){
    localStorage.setItem(getHousePreferenceKey(this.uid), id);
    this.loadHouse();
  }

  goToItem(item){
    console.log(item);
    this.router.navigate(['/item', { houseId: localStorage.getItem(getHousePreferenceKey(this.uid)), id: item.id }]);
  }

  navigateToRoom(roomId){
    this.router.navigate(['/room', { houseId:localStorage.getItem(getHousePreferenceKey(this.uid)), id: roomId }]);
  }

  openDialog(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {room: null, editing: false, uid: this.uid, houseId: localStorage.getItem(getHousePreferenceKey(this.uid))};

    this.dialog.open(AddhomedialogComponent, dialogConfig);

  }

  findDefaultHouse(){

    this.housesCollectionRef = this.afs.collection('Houses', ref => ref.where('Owners', 'array-contains', this.uid));
    
    this.housesCollectionRef.get().subscribe(data=>{

        if(data.size == 0) {
          this.createFirstHouse()
        }else{
          if(localStorage.getItem(getHousePreferenceKey(this.uid)) == null){
            localStorage.setItem(getHousePreferenceKey(this.uid), data.docs[0].id);
            this.houseSelectedService.changeSelectedHouse(data.docs[0].id)
            this.router.navigate(['/home', { id: localStorage.getItem(getHousePreferenceKey(this.uid))
            }]);

          }else{
            this.houseSelectedService.changeSelectedHouse(getHousePreferenceKey(this.uid))
            this.router.navigate(['/home', { id: localStorage.getItem(getHousePreferenceKey(this.uid))
            }]);
            console.log("preference key is not null")
          }
        }
     });
    
  }

  createFirstHouse() {
    this.showFirstTimeFlow();

    var houseName = '';
    if(this.displayName != null){
      houseName = this.displayName;
    }else{
      houseName = 'My House';
    }


    const callable = this.fireFunctions.httpsCallable('createNewHouse');
    callable({ name: houseName }).subscribe(response => {

      this.afAuth.currentUser.then(user => {
        user.reload().then(response1 => {
          user.getIdToken(true).then(response => {
            this.findDefaultHouse();
          })
  
        })
      })


    });


  }

showFirstTimeFlow(){
  const dialogConfig = new MatDialogConfig();

  dialogConfig.disableClose = true;
  dialogConfig.width = '350px';

  this.dialog.open(IntroDialog, dialogConfig);
  
localStorage.setItem("show_room_walkthrouth", "true");
localStorage.setItem("show_container_walkthrouth", "true");
localStorage.setItem("show_item_walkthrouth", "true");



}

  loadHouse(){
    var houseId = localStorage.getItem(getHousePreferenceKey(this.uid));
    this.roomsCollectionRef = this.afs.collection<Room>('Houses/' + houseId + '/Rooms', ref => ref.orderBy('name'));

    this.roomsCollectionRef.valueChanges({ idField: 'id' }).subscribe(data=>{
      this.rooms = data;
      this.roomsPlusImages = [];
      for (let room of this.rooms) {  
        this.roomsPlusImages.push(new RoomPlusImage(room));
      }

      for (let roomPlusImage of this.roomsPlusImages) {
        if(roomPlusImage.room.imageUrls && roomPlusImage.room.imageUrls.length>0){
          const fullRef = 'Houses/' + houseId + '/Rooms/' + roomPlusImage.room.imageUrls[0];
          const ref = this.storage.ref(fullRef);
          ref.getDownloadURL().subscribe(url=>{
            if(url){
              roomPlusImage.displayImage = url;
            }
         })
        }else{
          roomPlusImage.displayImage = 'assets/images/empty_room.png'
        }        
    }
     });

         this.getItems(localStorage.getItem(getHousePreferenceKey(this.uid)));


  }




loadHome() {


  if(localStorage.getItem(getHousePreferenceKey(this.uid))){
    this.loadHouse();
  }else{
    this.findDefaultHouse();
  }
}

  ngOnInit() {

  this.afAuth.authState.first().subscribe(res => {
    console.log(res);
    if (res && res.uid) {
      
      this.displayName = res.displayName
      this.uid = res.uid;
      this.houseSelectedService.selectedHouse.subscribe(selectedHouse => this.selectedHouse = selectedHouse)

      this.route.params.subscribe(params => {
  
        if(params.id != null){
        this.paramsChange(params.id);
      }else{
        this.findDefaultHouse();
      }
    });
    //this.loadHome()

    } else {
      console.log("logged Out");
      this.router.navigate(['/auth']);
    }
  });

  }

  getItems(houseId){
    this.itemsCollectionRef = this.afs.collection<Item>('Houses/' + houseId + '/Items', ref => ref.orderBy('name'));

    this.itemsCollectionRef.valueChanges({ idField: 'id' }).subscribe(data=>{
      this.remoteData = data;
      for (let item of this.remoteData) {
        if(item.imageUrls && item.imageUrls.length>0){
          const fullRef = 'Houses/' + houseId + '/Items/' + item.imageUrls[0];
          const ref = this.storage.ref(fullRef);
          ref.getDownloadURL().subscribe(url=>{
            if(url){
                item.displayImageUrl = url;
            }
         })
        }else{
          item.displayImageUrl = 'assets/images/cube_icon.png'
        }

      }
      //this.remoteData[0].displayImageUrl = 'assets/images/empty_room.png'

      this.filteredOptions = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
     });
  }

  private _filter(value: string): Item[] {
    console.log(value);
    const filterValue = value.toLowerCase();
    return this.remoteData.filter(option =>
      option.name.toLowerCase().includes(filterValue))
  }

  public openChart(evt, crypto) {
    this.router.navigate(['/statistics', { text: 'Volatility', iconName: 'show_chart',
      cryptoName: [crypto['name'], crypto['fullName']], daysCount: 100 }]);
  }





private onSuccess() {
  this.selectedFile.pending = false;
  this.selectedFile.status = 'ok';
}

processFile(imageInput: any) {
  const file: File = imageInput.files[0];
  const reader = new FileReader();

  reader.addEventListener('load', (event: any) => {

    this.selectedFile = new ImageSnippet(event.target.result, file);

    this.selectedFile.pending = true;
         this.onSuccess();
  });

  reader.readAsDataURL(file);
}


}