import { Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'introdialog',
    templateUrl: 'intro-dialog.html',
    styleUrls: ['./intro-dialog.css']
  })
  export class IntroDialog {
  
    constructor(
      public dialogRef: MatDialogRef<IntroDialog>) {}
  
      onOkClicked(): void {
      console.log("no clicked");
      this.dialogRef.close()
    }
  
  }