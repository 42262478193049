import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Room } from '../../shared/core/interfaces';
import { Storage } from '../../shared/core/interfaces';
import { Item } from '../../shared/core/interfaces';
import { StoragePlusImage } from '../../shared/core/interfaces';
import { ItemPlusImage } from '../../shared/core/interfaces';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { getHousePreferenceKey } from '../../shared/core/utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {Location} from '@angular/common';
import { AddhomedialogComponent } from '../../shared/addhomedialog/addhomedialog.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AddcontainerdialogComponent } from '@shared/addcontainerdialog/addcontainerdialog.component';
import { AdditemdialogComponent } from '@shared/additemdialog/additemdialog.component';

@Component({
  selector: 'app-room',
  templateUrl: './room.component.html',
  styleUrls: ['./room.component.scss']
})
export class RoomComponent implements OnInit {
  roomImageUrls: String[] = ["assets/images/empty_room.png"]
  public room: Room;
  public slides: any[] = [];
  public animations = ["slide", "fade", "none"];
  public density = "comfortable";
  public displayDensities;
  storageCollectionRef: AngularFirestoreCollection<Storage>;
  storagePlusImages: StoragePlusImage[];
  storages: Storage[];
  itemsCollectionRef: AngularFirestoreCollection<Item>;
  itemPlusImages: ItemPlusImage[];
  items: Item[];
  roomId: string;
  rooms: Room[];
  uid:string;
  houseId:string;

  constructor(private _snackBar: MatSnackBar, private _location: Location, public dialog: MatDialog, private router: Router, private storage: AngularFireStorage, public afAuth: AngularFireAuth, private route: ActivatedRoute, private afs: AngularFirestore) {}

  ngOnInit() {

    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        this.uid = res.uid;

        this.room = new Room();
        this.route.params.subscribe(params => {
          this.roomId = params['id'];
          this.houseId = params['houseId'];
          this.showHouse();
          this.getStorage();
          this.getItems();

          if(localStorage.getItem("show_room_walkthrouth")=="true"){

            this.showRoomWalkthrough()
            localStorage.setItem("show_room_walkthrouth", "false");

          }
    
        });
  
      } else {
        this.router.navigate(['/auth']);
      }
    });



  }

  showRoomWalkthrough(){
    this.dialog.open(RoomWalkthroughDialog, {
      width: '350px'  });
  }


  getStorage(){

    this.storageCollectionRef = this.afs.collection<Storage>('Houses/' + this.houseId + '/Storage', ref => ref.where('insideOfId', '==', this.roomId).orderBy('name'));

    this.storageCollectionRef.valueChanges({ idField: 'id' }).subscribe(data=>{
      this.storages = data;
      this.storagePlusImages = [];
      for (let storage of this.storages) {  
        this.storagePlusImages.push(new StoragePlusImage(storage));
      }

      for (let storagePlusImage of this.storagePlusImages) {
        if(storagePlusImage.storage.imageUrls && storagePlusImage.storage.imageUrls.length>0){
          const fullRef = 'Houses/' + this.houseId + '/Storage/' + storagePlusImage.storage.imageUrls[0];
          const ref = this.storage.ref(fullRef);
          ref.getDownloadURL().subscribe(url=>{
            if(url){
              storagePlusImage.displayImage = url;
            }
         })
        }else{
          storagePlusImage.displayImage = 'assets/images/cabinet.png'
        }        
    }
     });

  }

  getItems(){

    this.itemsCollectionRef = this.afs.collection<Item>('Houses/' + this.houseId + '/Items', ref => ref.where('insideOfId', '==', this.roomId).orderBy('name'));

    this.itemsCollectionRef.valueChanges({ idField: 'id' }).subscribe(data=>{
      this.items = data;
      this.itemPlusImages = [];
      for (let item of this.items) {  
        this.itemPlusImages.push(new ItemPlusImage(item));
      }

      for (let itemPlusImage of this.itemPlusImages) {
        if(itemPlusImage.item.imageUrls && itemPlusImage.item.imageUrls.length>0){
          const fullRef = 'Houses/' + this.houseId + '/Items/' + itemPlusImage.item.imageUrls[0];
          const ref = this.storage.ref(fullRef);
          ref.getDownloadURL().subscribe(url=>{
            if(url){
              itemPlusImage.displayImage = url;
            }
         })
        }else{
          itemPlusImage.displayImage = 'assets/images/cube_icon.png'
        }        
    }
     });

  }


  showHouse() {
    this.afs.collection('Houses/' + this.houseId + '/Rooms').doc<Room>(this.roomId).valueChanges().subscribe(data=>{
      this.room = data;
  
      this.room.id = this.roomId;
      this.getRoomImages(this.room.imageUrls)
   });
  }

  getRoomImages(imageUrls) {

    if(imageUrls.length >0){
    this.roomImageUrls = [];
    for (let image of imageUrls) {
        const fullRef = 'Houses/' + this.houseId + '/Rooms/' + image;
        const ref = this.storage.ref(fullRef);
        ref.getDownloadURL().subscribe(url=>{
          if(url){
            this.roomImageUrls.push(url);
          }
       })
  }
}
  }

  editRoomDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {room: this.room, editing: true, uid: this.uid, houseId: this.houseId};
    this.dialog.open(AddhomedialogComponent, dialogConfig);
  }

  goToContainer(storagePlusImage){
      this.router.navigate(['/container', { houseId: this.houseId, id: storagePlusImage.storage.id}]);
  }

  goToItem(itemPlusImage){
    this.router.navigate(['/item', { houseId: this.houseId, id: itemPlusImage.item.id }]);
}

  openDeleteRoomDialog(): void {
    const dialogRef = this.dialog.open(DeleteRoomDialog, {
      width: '250px'});
    
    dialogRef.afterClosed().subscribe(result => {
      if(result.didDelete){
        this.deleteRoom();
      }
    });

  }

  deleteRoom() {

    this.afs.collection<Room>('Houses/' + this.houseId + '/Rooms').doc(this.roomId).delete().then(response1 => {
      this._snackBar.open("Room deleted.", '', {
        duration: 3000
      });
      this._location.back();
    })

  }

  addItem(){
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {item: null, editing: false, insideOfId: this.roomId, uid: this.uid, houseId: this.houseId};

    this.dialog.open(AdditemdialogComponent, dialogConfig);

  }

  addContainer(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {container: null, editing: false, insideOfId: this.roomId, uid: this.uid, houseId: this.houseId};

    this.dialog.open(AddcontainerdialogComponent, dialogConfig);

  }

}


@Component({
  selector: 'delete-room-dialog',
  templateUrl: 'delete-room-dialog.html',
})
export class DeleteRoomDialog {

  constructor(
    public dialogRef: MatDialogRef<DeleteRoomDialog>) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    this.dialogRef.close({ didDelete: true });
  }

}

@Component({
  selector: 'roomwalkthrouthdialog',
  templateUrl: 'room-walkthrough-dialog.html',
})
export class RoomWalkthroughDialog {

  constructor(
    public dialogRef: MatDialogRef<RoomWalkthroughDialog>) {}

  onOkClicked(): void {
    this.dialogRef.close();
  }

}