import * as firebase from 'firebase';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { House } from '../../../shared/core/interfaces';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { getHousePreferenceKey } from '../../../shared/core/utils';
import { Router } from '@angular/router';
import { HouseSelectedService } from "../../../shared/services/houseselected.service";

import { AuthService, AlertService } from '../../services';

@Component({
  selector: 'app-header',
  templateUrl: 'header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  public isAuthenticated: string;
  public angularImage: string = '/assets/images/ic_launcher.png';
  houses: House[];
  selected: House;
  selectedHouse:string;
  uid:string;
  public menuItems: Object[] = [

  ];

  

  constructor(
    public authService: AuthService,
    private alertService: AlertService,
    public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private data: HouseSelectedService
    ) {
      this.isAuthenticated = this.authService.isAuthenticated()
  }

  ngOnInit() {

    this.afAuth.authState.subscribe(res => {
      console.log(res);
      if (res && res.uid) {
        this.uid = res.uid;
        this.loadHome()
        this.data.selectedHouse.subscribe(selectedHouse => this.updateSelected())
      } else {
        console.log("logged Out");
      }
    });


  }

  async loadHome() {

    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
    
        this.loadHousesList();
  
      } else {
        console.log("logged Out");
      }
    });
  }

  loadHousesList(){
    this.afs.collection<House>('Houses', ref => ref.where('Owners', 'array-contains', this.uid)).snapshotChanges().subscribe(actionArray => {
      this.houses = actionArray.map(item => {
        return {
          id: item.payload.doc.id,
          ...item.payload.doc.data()
        } as House
      })
    
      for (let i in this.houses) {
        this.afs.collection('Houses/' + this.houses[i].id + '/PrivateData').doc("HouseData").get().subscribe(data=>{
          this.houses[i].name = data.data().name;
       });

       if(this.houses[i].id == localStorage.getItem(getHousePreferenceKey(this.uid))){
        this.selected = this.houses[i];
       }

      }



    });
    


  }

  updateSelected(){
    for (let i in this.houses) {

     if(this.houses[i].id == localStorage.getItem(getHousePreferenceKey(this.uid))){
      this.selected = this.houses[i];

     }

    }
  }

  handleSelection($event){
    if(localStorage.getItem(getHousePreferenceKey(this.uid)) != $event.value.id){
      localStorage.setItem(getHousePreferenceKey(this.uid), $event.value.id);
      this.router.navigate(['/home', { id: $event.value.id }]);

    }
  }

  public userUid(): string {
    return firebase.auth().currentUser.uid;
  }

  public userEmail(): string {
    return firebase.auth().currentUser.email;
  }

  public userName(): string {
    return firebase.auth().currentUser.displayName;
  }

  public onLogout(): void {
    this.alertService.showToaster('Logout successful');
    return this.authService.logout();
  }
}
