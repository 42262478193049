import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storage } from '../../shared/core/interfaces';
import { Item } from '../../shared/core/interfaces';
import { StoragePlusImage } from '../../shared/core/interfaces';
import { ItemPlusImage } from '../../shared/core/interfaces';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { getHousePreferenceKey } from '../../shared/core/utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AddcontainerdialogComponent } from '@shared/addcontainerdialog/addcontainerdialog.component';
import { AdditemdialogComponent } from '@shared/additemdialog/additemdialog.component';
import { InsideofService } from '@shared';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss']
})
export class ContainerComponent implements OnInit {
  containerImageUrls: String[] = ["assets/images/cabinet.png"]
  public container: Storage;
  public slides: any[] = [];
  public animations = ["slide", "fade", "none"];
  public density = "comfortable";
  public displayDensities;
  storageCollectionRef: AngularFirestoreCollection<Storage>;
  storagePlusImages: StoragePlusImage[];
  storages: Storage[];
  itemsCollectionRef: AngularFirestoreCollection<Item>;
  itemPlusImages: ItemPlusImage[];
  items: Item[];
  containerId: string;
  houseId: string;
  containerLocation = ""
  uid:string;
  currentSubscriptions = [];

  constructor(private _insideOfService: InsideofService, private _snackBar: MatSnackBar, private _location: Location, public dialog: MatDialog, private router: Router, private storage: AngularFireStorage, public afAuth: AngularFireAuth, private route: ActivatedRoute, private afs: AngularFirestore) {

    this._insideOfService.insideOfString.subscribe(insideOfString=>{
      console.log(insideOfString)
      this.containerLocation = insideOfString;
   })

  }

  ngOnInit() {

    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        this.uid = res.uid;


        this.container = new Storage();
        this.route.params.subscribe(params => {
          
          this.containerId = params['id'];
          this.houseId = params['houseId'];

          for (let currentSubscription of this.currentSubscriptions) {
            currentSubscription.unsubscribe();
        }
        this.currentSubscriptions = [];
        this.containerImageUrls = ["assets/images/cabinet.png"];

          this.showContainer();
          this.getStorage();
          this.getItems();
        
          if(localStorage.getItem("show_container_walkthrouth")=="true"){
            this.showContainerWalkthrough()
            localStorage.setItem("show_container_walkthrouth", "false");
          }



        });
  
      } else {
        this.router.navigate(['/auth']);
      }
    });



  }

  showContainerWalkthrough() {
    this.dialog.open(ContainerWalkthroughDialog, {
        width: '350px'  });
    
  }


  getStorage(){
    var houseId = localStorage.getItem(getHousePreferenceKey(this.uid));

    this.storageCollectionRef = this.afs.collection<Storage>('Houses/' + houseId + '/Storage', ref => ref.where('insideOfId', '==', this.containerId).orderBy('name'));

   this.currentSubscriptions.push(this.storageCollectionRef.valueChanges({ idField: 'id' }).subscribe(data=>{
      console.log(data)
      this.storages = data;
      this.storagePlusImages = [];
      for (let storage of this.storages) {  
        this.storagePlusImages.push(new StoragePlusImage(storage));
      }

      for (let storagePlusImage of this.storagePlusImages) {
        if(storagePlusImage.storage.imageUrls && storagePlusImage.storage.imageUrls.length>0){
          const fullRef = 'Houses/' + houseId + '/Storage/' + storagePlusImage.storage.imageUrls[0];
          const ref = this.storage.ref(fullRef);
          ref.getDownloadURL().subscribe(url=>{
            if(url){
              storagePlusImage.displayImage = url;
            }
         })
        }else{
          storagePlusImage.displayImage = 'assets/images/cabinet.png'
        }        
    }
     }));

  }

  getItems(){
    var houseId = localStorage.getItem(getHousePreferenceKey(this.uid));

    this.itemsCollectionRef = this.afs.collection<Item>('Houses/' + houseId + '/Items', ref => ref.where('insideOfId', '==', this.containerId).orderBy('name'));

    this.currentSubscriptions.push(this.itemsCollectionRef.valueChanges({ idField: 'id' }).subscribe(data=>{
      this.items = data;
      this.itemPlusImages = [];
      for (let item of this.items) {  
        this.itemPlusImages.push(new ItemPlusImage(item));
      }

      for (let itemPlusImage of this.itemPlusImages) {
        if(itemPlusImage.item.imageUrls && itemPlusImage.item.imageUrls.length>0){
          const fullRef = 'Houses/' + houseId + '/Items/' + itemPlusImage.item.imageUrls[0];
          const ref = this.storage.ref(fullRef);
          ref.getDownloadURL().subscribe(url=>{
            if(url){
              itemPlusImage.displayImage = url;
            }
         })
        }else{
          itemPlusImage.displayImage = 'assets/images/cube_icon.png'
        }        
    }
     }));

  }


  showContainer() {
    this.currentSubscriptions.push(this.afs.collection('Houses/' + this.houseId + '/Storage').doc<Storage>(this.containerId).valueChanges().subscribe(data=>{
      this.container = data;
  
      this.container.id = this.containerId;
      this._insideOfService.getInsideOfString(this.container.insideOfId, this.uid);

      this.getContainerImages(this.container.imageUrls)
   }));
  }

  getContainerImages(imageUrls) {
    if(imageUrls.length >0){

    this.containerImageUrls = [];
    for (let image of imageUrls) {
        const fullRef = 'Houses/' + localStorage.getItem(getHousePreferenceKey(this.uid)) + '/Storage/' + image;
        const ref = this.storage.ref(fullRef);
        ref.getDownloadURL().subscribe(url=>{
          if(url){
            this.containerImageUrls.push(url);
          }
       })
  }
}
  }

  editContainerDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {container: this.container, editing: true, insideOfId: this.containerId, uid: this.uid, houseId: this.houseId};
    this.dialog.open(AddcontainerdialogComponent, dialogConfig);
  }

  openDeleteContainerDialog(): void {
    const dialogRef = this.dialog.open(DeleteContainerDialog, {
      width: '250px'});
    
    dialogRef.afterClosed().subscribe(result => {
      if(result.didDelete){
        this.deleteContainer();
      }
    });

  }

  deleteContainer() {

    this.afs.collection<Storage>('Houses/' + localStorage.getItem(getHousePreferenceKey(this.uid)) + '/Storage').doc(this.containerId).delete().then(response1 => {
      this._snackBar.open("Container deleted.", '', {
        duration: 3000
      });
      this._location.back();
    })

  }

  goToContainer(storagePlusImage){
    this.router.navigate(['/container', {houseId: this.houseId, id: storagePlusImage.storage.id }]);
}

goToItem(itemPlusImage){
  this.router.navigate(['/item', {houseId: this.houseId, id: itemPlusImage.item.id }]);
}

  addItem(){
    
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {addItem: null, editing: false, insideOfId: this.containerId, uid: this.uid};

    this.dialog.open(AdditemdialogComponent, dialogConfig);

  }

  addContainer(): void {

    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {container: null, editing: false, insideOfId: this.containerId, uid: this.uid, houseId: this.houseId};

    this.dialog.open(AddcontainerdialogComponent, dialogConfig);

  }

}


@Component({
  selector: 'delete-container-dialog',
  templateUrl: 'delete-container-dialog.html',
})
export class DeleteContainerDialog {

  constructor(
    public dialogRef: MatDialogRef<DeleteContainerDialog>) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    this.dialogRef.close({ didDelete: true });
  }

}

@Component({
  selector: 'containerwalkthrouthdialog',
  templateUrl: 'container-walkthrough-dialog.html',
})
export class ContainerWalkthroughDialog {

  constructor(
    public dialogRef: MatDialogRef<ContainerWalkthroughDialog>) {}

  onOkClicked(): void {
    this.dialogRef.close();
  }

}