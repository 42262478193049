// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
// import 'zone.js/dist/zone-error';


export const environment = {
  production: false,


  // firebaseConfig : {
  //   apiKey: "AIzaSyANzmLBRizrfkmTMSFJZfmJW14y1UIldbQ",
  //   authDomain: "wairz-d5aee.firebaseapp.com",
  //   databaseURL: "https://wairz-d5aee.firebaseio.com",
  //   projectId: "wairz-d5aee",
  //   storageBucket: "wairz-d5aee.appspot.com",
  //   messagingSenderId: "968890589620",
  //   appId: "1:968890589620:web:8be6986f7631a1688e52e1",
  //   measurementId: "G-9GKXGTDQSX"
  // }

      //prod
      firebaseConfig : {
        apiKey: "AIzaSyC3jY8_MQV5Goy3ZLwfUcXfL-y4AKTINt8",
        authDomain: "homelog-3ce86.firebaseapp.com",
        databaseURL: "https://homelog-3ce86.firebaseio.com",
        projectId: "homelog-3ce86",
        storageBucket: "homelog-3ce86.appspot.com",
        messagingSenderId: "668825382726",
        appId: "1:668825382726:web:7ad8e5373e8035a810bf47",
        measurementId: "G-5JQV74TNR4"
      }

};
