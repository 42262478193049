
const baseUrl = 'https://www.cryptocompare.com';

export function sortDataByKey(array, keyToSortBy) {
    function sortByKey(a, b) {
        const x = a[keyToSortBy];
        const y = b[keyToSortBy];
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    }

    return array.sort(sortByKey);
}

export function flattenObject(ob) {
    const toReturn = {};

    for (const i in ob) {
        if (!ob.hasOwnProperty(i)) { continue; }

        if ((typeof ob[i]) === 'object') {
            const flatObject = flattenObject(ob[i]);
            for (const x in flatObject) {
                if (!flatObject.hasOwnProperty(x) || i === 'DISPLAY') { continue; }
                toReturn[x] = flatObject[x];
            }
        } else {
            toReturn[i.toUpperCase()] = ob[i];
        }
    }
    return toReturn;
}

export function transformCoinImgUrl(imgUrl: string) {
    return baseUrl + imgUrl;
}


export function fillFromJSON(obj, jsonObj) {
    for (const propName in obj) {
        if (propName === 'name' && jsonObj['FROMSYMBOL'] !== undefined) {
            obj['name'] = jsonObj['FROMSYMBOL'];
            obj['fullName'] = jsonObj['FROMSYMBOL'];
        } else { obj[propName] = jsonObj[propName.toUpperCase()]; }
    }

    return obj;
}

export function getHousePreferenceKey(uid) {
    return uid + "_" + "HOUSE_PREF"
}


export function getFormattedTime() {
    var today = new Date();
    var y = today.getFullYear();
    // JavaScript months are 0-based.
    var m = today.getMonth() + 1;
    var d = today.getDate();
    var h = today.getHours();
    var mi = today.getMinutes();
    var s = today.getSeconds();
    return y + "-" + m + "-" + d + "-" + h + "-" + mi + "-" + s;
}

export function getCompressionSizeForRoom(sizeOfImage) {
    if(sizeOfImage>5000000){
        return 2;
    }else if(sizeOfImage>2000000){
        return 14;
    }else if(sizeOfImage>1000000){
        return 18;
    }else if(sizeOfImage>100000){
        return 50;
    }else{
        return 80;
    }
}

export function getRatioSizeForRoom(sizeOfImage) {
    if(sizeOfImage>5000000){
        return 20;
    }else if(sizeOfImage>2000000){
        return 30;
    }else if(sizeOfImage>1000000){
        return 40;
    }else if(sizeOfImage>100000){
        return 50;
    }else{
        return 70;
    }
}

export function getCompressionSizeForContainer(sizeOfImage) {
    if(sizeOfImage>5000000){
        return 2;
    }else if(sizeOfImage>2000000){
        return 12;
    }else if(sizeOfImage>1000000){
        return 15;
    }else if(sizeOfImage>100000){
        return 50;
    }else{
        return 80;
    }
}

export function getRatioSizeForContainer(sizeOfImage) {
    if(sizeOfImage>5000000){
        return 20;
    }else if(sizeOfImage>2000000){
        return 30;
    }else if(sizeOfImage>1000000){
        return 40;
    }else if(sizeOfImage>100000){
        return 50;
    }else{
        return 70;
    }
}

export function getCompressionSizeForItem(sizeOfImage) {
    if(sizeOfImage>5000000){
        return 2;
    }else if(sizeOfImage>2000000){
        return 12;
    }else if(sizeOfImage>1000000){
        return 15;
    }else if(sizeOfImage>100000){
        return 50;
    }else{
        return 80;
    }
}

export function getRatioSizeForItem(sizeOfImage) {
    if(sizeOfImage>5000000){
        return 20;
    }else if(sizeOfImage>2000000){
        return 30;
    }else if(sizeOfImage>1000000){
        return 40;
    }else if(sizeOfImage>100000){
        return 50;
    }else{
        return 70;
    }
}

export function dataURItoBlob(dataURI) {

    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });    
    return blob;
  }