// Modules 3rd party
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxAuthFirebaseUIModule } from 'ngx-auth-firebaseui';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { FlexLayoutModule } from '@angular/flex-layout';
import {MatListModule} from '@angular/material/list';
import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/analytics';

// Modules
import { BlocksModule } from './components/blocks/blocks.module';
import { AuthModule } from './pages/auth/auth.module';
import { BackgroundsModule } from './components/backgrounds/backgrounds.module';
import { ProfileModule } from './pages/profile/profile.module';
import { MiscModule } from './components/misc/misc.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {SlideshowModule} from 'ng-simple-slideshow';
import {MatChipsModule} from '@angular/material/chips';
import { NgImageSliderModule } from 'ng-image-slider';
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { Ng2ImgMaxModule } from 'ng2-img-max';

// Shared
import {
  FooterComponent,
  HeaderComponent,
  UserService,
  AlertService,
  AuthGuardService,
  AuthService,
  WindowService,
  InsideofService,
  HouseSelectedService
} from '@shared';

// Main
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { firebaseKeys } from './firebase.config';

// Pages
import { RoomComponent } from './pages/room/room.component';
import { ContainerComponent } from './pages/container/container.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutMeComponent } from './pages/about-me/about-me.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PageNotFoundComponent } from './pages/not-found/not-found.component';

// Components
import { EmailMeComponent } from './components/email-me/email-me.component';
import { AddhomedialogComponent } from './shared/addhomedialog/addhomedialog.component';
import { IntroDialog } from './pages/home/intro-dialog.component';
import {RoomWalkthroughDialog} from './pages/room/room.component'

import { ItemComponent } from './pages/item/item.component';


import {DeleteItemDialog} from './pages/item/item.component';
import {DeleteRoomDialog} from './pages/room/room.component';
import {DeleteContainerDialog} from './pages/container/container.component';
import {ContainerWalkthroughDialog} from './pages/container/container.component';

import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import { AddcontainerdialogComponent } from './shared/addcontainerdialog/addcontainerdialog.component';
import { AdditemdialogComponent } from './shared/additemdialog/additemdialog.component';
import { CurrencyMaskInputMode, NgxCurrencyModule, CurrencyMaskConfig } from "ngx-currency";
import {NgxImageCompressService} from 'ngx-image-compress';

// currently there is a bug while building the app with --prod
// - https://github.com/RaphaelJenni/FirebaseUI-Angular/issues/76
// the plugin exposes the two libraries as well. You can use those:
// import {FirebaseUIModule} from 'firebaseui-angular';
// import * as firebase from 'firebase/app';
// import * as firebaseui from 'firebaseui'; 

import { FirebaseUIModule, firebase, firebaseui } from 'firebaseui-angular';
 
import {AngularFireModule} from '@angular/fire';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {environment} from '../environments/environment';


const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    {
      scopes: [
        'public_profile',
        'email',
        'user_likes',
        'user_friends'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
      provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID
    },
    firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    firebase.auth.GithubAuthProvider.PROVIDER_ID,
    {
      requireDisplayName: false,
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID
    },
    firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  tosUrl: '<your-tos-link>',
  privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
  credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM
};


// export const customCurrencyMaskConfig : CurrencyMaskConfig = {
//   align: "left",
//   allowNegative: false,
//   allowZero: true,
//   nullable: true,
//   inputMode: CurrencyMaskInputMode.NATURAL
// };


@NgModule({
  declarations: [
    AppComponent,
    AboutMeComponent,
    ContactComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    EmailMeComponent,
    HomeComponent,
    AddhomedialogComponent,
    IntroDialog,
    ContainerWalkthroughDialog,
    RoomWalkthroughDialog,
    RoomComponent,
    ContainerComponent,
    ItemComponent,
    DeleteRoomDialog,
    DeleteItemDialog,
    DeleteContainerDialog,
    AddcontainerdialogComponent,
    AdditemdialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule, MatCheckboxModule, MatMenuModule, MatInputModule, MatSnackBarModule,
    MatToolbarModule, MatDialogModule, MatSidenavModule,
    MatCardModule, MatTabsModule, MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    PipesModule,
    BlocksModule,
    AuthModule,
    BackgroundsModule,
    ProfileModule,
    MiscModule,
    NgxAuthFirebaseUIModule.forRoot(
      
        firebaseKeys
      ,
       null,
      {
        enableFirestoreSync: false,
        enableEmailVerification: false,
        guardProtectedRoutesUntilEmailIsVerified: false,
        toastMessageOnAuthSuccess: false
      }),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    MatSelectModule,
    FlexLayoutModule,
    MatListModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    NgxDropzoneModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    SlideshowModule,
    NgxCurrencyModule,
    MatChipsModule,
    NgImageSliderModule,
    MatCarouselModule,
    AngularFireModule,
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAnalyticsModule,
    Ng2ImgMaxModule
  ],
  entryComponents: [IntroDialog, RoomWalkthroughDialog, ContainerWalkthroughDialog, AddhomedialogComponent, DeleteRoomDialog, DeleteContainerDialog, DeleteItemDialog, AddcontainerdialogComponent, AdditemdialogComponent],
  providers: [
    UserService,
    AlertService,
    AuthGuardService,
    AuthService,
    WindowService,
    InsideofService,
    HouseSelectedService,
    NgxImageCompressService,
    UserTrackingService
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
