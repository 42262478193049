import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Item } from '../core/interfaces';
import { AlreadySelectedImage } from '../../shared/core/interfaces';
import { PreviewImage } from '../../shared/core/interfaces';
import { ResizedFile } from '../../shared/core/interfaces';
import { getHousePreferenceKey, getFormattedTime, getCompressionSizeForItem, getRatioSizeForItem, dataURItoBlob} from '../core/utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';
export interface ItemDialogData {
  editing: boolean;
  addItem: Item;
  insideOfId;
  uid:string;
}

@Component({
  selector: 'app-additemdialog',
  templateUrl: './additemdialog.component.html',
  styleUrls: ['./additemdialog.component.scss']
})
export class AdditemdialogComponent {

  itemsCollectionRef: AngularFirestoreCollection<Item>;
  originalItemImages = [];
  previewImages: PreviewImage[] = [];
  itemNameControl = new FormControl("", Validators.required);
  itemDescriptionControl = new FormControl();
  itemPriceControl = new FormControl();
  itemSerialNumberControl = new FormControl();
  itemBrandControl = new FormControl();
  itemModelControl = new FormControl();
  itemQuantityControl = new FormControl(1);

  title;
  itemForm;
  loading = false;
  constructor(public sanitizer: DomSanitizer, private ng2ImgMaxService: Ng2ImgMaxService, private afs: AngularFirestore, private router: Router, private storage: AngularFireStorage, public afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<AdditemdialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ItemDialogData) {


      this.itemForm = new FormGroup({
        itemname: this.itemNameControl,
        itemdescription: this.itemDescriptionControl,
        itemprice: this.itemPriceControl,
        itembrand: this.itemBrandControl,
        itemmodel: this.itemModelControl,
        itemquantity: this.itemQuantityControl
       }); 


    if(data.editing){
      this.title = "Edit Item"
    for (let selectedUrl of data.addItem.imageUrls) {
      this.originalItemImages.push(new AlreadySelectedImage(selectedUrl));
    }
    this.setForm(data);

  }else{
    this.title = "Add Item"

  }
  
  var houseId = localStorage.getItem(getHousePreferenceKey(this.data.uid));

    for (let originalItemImage of this.originalItemImages) {
        const fullRef = 'Houses/' + houseId + '/Items/' + originalItemImage.imageName;
        const ref = this.storage.ref(fullRef);
        ref.getDownloadURL().subscribe(url=>{
          if(url){
            originalItemImage.fullUrl = url;
          }
       })
       
  }

    }

    setForm(data){
      const item = data.addItem;
      this.itemNameControl.setValue(item.name);
      this.itemDescriptionControl.setValue(item.description);
      if(item.price != null && item.price != ""){
        this.itemPriceControl.setValue(Number(item.price));
      }
      this.itemSerialNumberControl.setValue(item.serialNumber);
      this.itemBrandControl.setValue(item.brand);
      this.itemModelControl.setValue(item.model);
      this.itemQuantityControl.setValue(item.quantity);
      this.additemtags = item.tags;
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public addItem(form: NgForm) {
    this.loading = true;
    if(this.previewImages.length>0){


           const pathToItemsImages = 'Houses/' + localStorage.getItem(getHousePreferenceKey(this.data.uid)) + '/Items/';  

            var promises = [];
            for (let previewImage of this.previewImages) {
            promises.push(this.storage.upload(`${pathToItemsImages}${previewImage.name}`, previewImage.file));
            }

            Promise.all(promises).then(tasks => {
            
              this.saveItemData(this.previewImages)
              }).catch(err => console.log("Error uploading files"));

    }else{
      this.saveItemData(null);
    }
  
}

deleteOriginalItem(index){
  this.originalItemImages.splice(index,1);
}

deletePreviewImage(index){
  this.previewImages.splice(index, 1);
}

saveItemData(previewImages){
const item = new Item()

if(this.data.editing){
  item.insideOfId = this.data.addItem.insideOfId;
}else{
  item.insideOfId = this.data.insideOfId;
}

item.name= this.itemNameControl.value;

item.description = this.itemDescriptionControl.value;
item.quantity = this.itemQuantityControl.value;
if(this.itemPriceControl.value != null){
  console.log(this.itemPriceControl.value);
  item.price= this.itemPriceControl.value.toFixed(2).toString();
  }

  item.brand = this.itemBrandControl.value;
  item.model = this.itemModelControl.value;
  item.serialNumber = this.itemSerialNumberControl.value;
  item.tags = this.additemtags;

var imagesArray = [];
if(this.originalItemImages != null){
  for (let originalItemImage of this.originalItemImages) {
    imagesArray.push(originalItemImage.imageName);
  }
}

if(previewImages != null){
  for (let previewImage of previewImages) {
    imagesArray.push(previewImage.name);
    }
}


item.imageUrls = imagesArray;


this.itemsCollectionRef = this.afs.collection<Item>('Houses/' + localStorage.getItem(getHousePreferenceKey(this.data.uid)) + '/Items');

if(this.data.editing){
  console.log("Editing Item with id " + this.data.addItem.id);

  console.log(item);
  this.itemsCollectionRef.doc(this.data.addItem.id).update({...item}).then(response1 => {
    this.dialogRef.close();
  })

  
}else{

this.itemsCollectionRef.add({...item}).then(response1 => {
  this.dialogRef.close();
})

}
}


onSelect(event) {
  if(this.hasAnImageAlreadySelected()){
    this.originalItemImages = [];
    this.previewImages = []
  }

  this.ng2ImgMaxService.compressImage(event.addedFiles[0], .20, true).subscribe((result)=>{ //todo sizing

    const imageFile = new File([result], getFormattedTime());
    const reader: FileReader = new FileReader();
    reader.readAsDataURL(imageFile);
    reader.onload = () => {
      this.previewImages.push(new PreviewImage(imageFile, imageFile.name, reader.result));
    };

  },
  error => {
    console.log('Error compressing image', error);
  });
}

hasAnImageAlreadySelected(){
  return this.originalItemImages.length>0 || this.previewImages.length>0;
}







visible: boolean = true;
selectable: boolean = true;
removable: boolean = true;
addOnBlur: boolean = true;

// Enter, comma
separatorKeysCodes = [ENTER, COMMA];

additemtags = [];


add(event: MatChipInputEvent): void {
  let input = event.input;
  let value = event.value;

  // Add our keyword
  if ((value || '').trim()) {
    this.additemtags.push(value.trim());
  }

  // Reset the input value
  if (input) {
    input.value = '';
  }

}

remove(tag: any): void {
  let index = this.additemtags.indexOf(tag);

  if (index >= 0) {
    this.additemtags.splice(index, 1);
  }
}

}


