import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Storage } from '../../shared/core/interfaces';
import { Item } from '../../shared/core/interfaces';
import { StoragePlusImage } from '../../shared/core/interfaces';
import { ItemPlusImage } from '../../shared/core/interfaces';
import { Router } from '@angular/router';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { getHousePreferenceKey } from '../../shared/core/utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig} from '@angular/material/dialog';
import {Location} from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import { AdditemdialogComponent } from '@shared/additemdialog/additemdialog.component';
import { InsideofService } from '@shared';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit {
  itemImageUrls: String[] = ["assets/images/cube_icon.png"]
  public item: Item;
  public slides: any[] = [];
  itemPlusImages: ItemPlusImage[];
  itemId: string;
  itemLocation = ""
  uid:string;

  constructor(private _insideOfService: InsideofService, private _snackBar: MatSnackBar, private _location: Location, public dialog: MatDialog, private router: Router, private storage: AngularFireStorage, public afAuth: AngularFireAuth, private route: ActivatedRoute, private afs: AngularFirestore) {

    this._insideOfService.insideOfString.subscribe(insideOfString=>{
      this.itemLocation = insideOfString;
   })

  }

  ngOnInit() {

    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        this.uid = res.uid;


        this.item = new Item();
        this.route.params.subscribe(params => {
          this.itemId = params['id'];
          this.showItem();
        });
  
      } else {
        this.router.navigate(['/auth']);
      }
    });



  }




  showItem() {
    this.afs.collection('Houses/' + localStorage.getItem(getHousePreferenceKey(this.uid)) + '/Items').doc<Item>(this.itemId).valueChanges().subscribe(data=>{
      this.item = data;
  
      this.item.id = this.itemId;
      this._insideOfService.getInsideOfString(this.item.insideOfId, this.uid);

      this.getItemImages(this.item.imageUrls)
   });
  }

  getItemImages(imageUrls) {
    if(imageUrls.length >0){

    this.itemImageUrls = [];
    for (let image of imageUrls) {
        const fullRef = 'Houses/' + localStorage.getItem(getHousePreferenceKey(this.uid)) + '/Items/' + image;
        const ref = this.storage.ref(fullRef);
        ref.getDownloadURL().subscribe(url=>{
          if(url){
            this.itemImageUrls.push(url);
          }
       })
  }
}
  }

  editItemDialog(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.data = {addItem: this.item, editing: true, insideOfId: this.itemId, uid: this.uid};
    this.dialog.open(AdditemdialogComponent, dialogConfig);
  }

  openDeleteItemDialog(): void {
    const dialogRef = this.dialog.open(DeleteItemDialog, {
      width: '250px'});
    
    dialogRef.afterClosed().subscribe(result => {
      if(result.didDelete){
        this.deleteItem();
      }
    });

  }

  deleteItem() {

    this.afs.collection<Storage>('Houses/' + localStorage.getItem(getHousePreferenceKey(this.uid)) + '/Items').doc(this.itemId).delete().then(response1 => {
      this._snackBar.open("Item deleted.", '', {
        duration: 3000
      });
      this._location.back();
    })

  }

}


@Component({
  selector: 'delete-item-dialog',
  templateUrl: 'delete-item-dialog.html',
})
export class DeleteItemDialog {

  constructor(
    public dialogRef: MatDialogRef<DeleteItemDialog>) {}

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onDeleteClick(): void {
    this.dialogRef.close({ didDelete: true });
  }

}