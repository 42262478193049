export interface CryptoCoin {
   name: string;
   symbol: string;
}

export class CoinItem {
   id = 0;                 // CoinInfo.Id
   name = '';              // CoinInfo.Name
   fullName = '';          // CoinInfo.FullName
   price = 0;              // RAW.USD.PRICE
   supply = 0;             // RAW.USD.SUPPLY
   changePct24Hour = 0;    // RAW.USD.CHANGEPCT24HOUR
   dailyScale: boolean;            // RAW.USD.DAILYSCALE
   proofType = '';         // CoinInfo.ProofType
   algorithm = '';         // CoinInfo.Algorithm
   rank = 0;               // CoinInfo.Rank
   imageUrl = '';          // RAW.USD.IMAGEURL
   change24Hour = 0;       // RAW.USD.CHANGE24HOUR(dollars)
   high24Hour = 0;         // RAW.USD.HIGH24HOUR(dollars)
   volume24Hour = 0;       // RAW.USD.VOLUME24HOUR(dollars)
   mktcap = 0;             // RAW.USD.MKTCAP
}

export class BlockItem {
   name = '';
   holdings = 0;
   supply = 0;
   changePct24Hour = 0;
   price = 0;
   imageUrls = [];
   displayImageUrl = '';
}

export class Room {
   id;
   name = '';
   imageUrls = [];
}

export class Storage {
   id;
   insideOfId = '';
   name = '';
   price = '';
   imageUrls = [];
}

export class Item {
   id;
   insideOfId = '';
   name = '';
   price = '';
   description = '';
   brand = '';
   model = '';
   serialNumber = '';
   imageUrls = [];
   displayImageUrl = '';
   tags = [];
   quantity = 1;
}

export class House {
   name = '';
   id;
}


export class StoragePlusImage {
   displayImage: string = 'assets/images/cabinet.png'
   constructor(public storage: Storage) {}
 }
 
 export class ItemPlusImage {
   displayImage: string = 'assets/images/cube_icon.png'
   constructor(public item: Item) {}
 }


export class AlreadySelectedImage {
   fullUrl: string = 'assets/images/empty_room.png'
   constructor(public imageName: string) {}
 }
 
 export class PreviewImage {
   constructor(public file: File, public name: string, public result: any) {}
 }
 
 export class ResizedFile {
   constructor(public file: File, public name: string) {}
 }

 export class StringAndImage {
    constructor(public name: string, public url: string){}
 }