import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Room } from '../core/interfaces';
import { AlreadySelectedImage } from '../../shared/core/interfaces';
import { PreviewImage } from '../../shared/core/interfaces';
import { getHousePreferenceKey, getFormattedTime, getCompressionSizeForRoom, getRatioSizeForRoom, dataURItoBlob} from '../core/utils';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';

export interface DialogData {
  editing: boolean;
  room: Room;
  uid: string;
  houseId: string;
}

@Component({
  selector: 'app-addhomedialog',
  templateUrl: './addhomedialog.component.html',
  styleUrls: ['./addhomedialog.component.scss']
})
export class AddhomedialogComponent {

  roomsCollectionRef: AngularFirestoreCollection<Room>;
  originalRoomImages = [];
  previewImages: PreviewImage[] = [];
  roomNameControl = new FormControl("", Validators.required);
  title;
  roomForm;
  loading = false;
  constructor(public sanitizer: DomSanitizer, private ng2ImgMaxService: Ng2ImgMaxService, private afs: AngularFirestore, private router: Router, private storage: AngularFireStorage, public afAuth: AngularFireAuth,
    public dialogRef: MatDialogRef<AddhomedialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {

      this.roomForm = new FormGroup({
        roomname: this.roomNameControl
       }); 


    if(data.editing){
      this.title = "Edit Room"
      if(data.room.imageUrls != null){
    for (let selectedUrl of data.room.imageUrls) {
      this.originalRoomImages.push(new AlreadySelectedImage(selectedUrl));
    }
  }
    this.setForm(data);

  }else{
    this.title = "Add Room"

  }
  
  var houseId = localStorage.getItem(getHousePreferenceKey(this.data.uid));

    for (let originalRoomImage of this.originalRoomImages) {
        const fullRef = 'Houses/' + houseId + '/Rooms/' + originalRoomImage.imageName;
        const ref = this.storage.ref(fullRef);
        ref.getDownloadURL().subscribe(url=>{
          if(url){
            originalRoomImage.fullUrl = url;
          }
       })
       
  }

    }

    setForm(data){
      const room = data.room;
      this.roomNameControl.setValue(room.name);
    }

  onNoClick(): void {
    this.dialogRef.close();
  }

  public addRoom(form: NgForm) {

    this.loading = true;
    if(this.previewImages.length>0){

           const pathToRoomsImages = 'Houses/' + localStorage.getItem(getHousePreferenceKey(this.data.uid)) + '/Rooms/';  

            var promises = [];
            for (let previewImage of this.previewImages) {
              var location = `${pathToRoomsImages}${previewImage.file.name}`;

            promises.push(this.storage.upload(location, previewImage.file));
            }

            Promise.all(promises).then(tasks => {
              this.saveRoomData(this.previewImages)
              }).catch(err => console.log(err));

          



    }else{
      this.saveRoomData(null);
    }
  
}

deleteOriginalRoom(index){
  this.originalRoomImages.splice(index,1);
}

deletePreviewImage(index){
  this.previewImages.splice(index, 1);
}

saveRoomData(resizedFiles){
const room = new Room();
room.name= this.roomNameControl.value;

var imagesArray = [];
if(this.originalRoomImages != null){
  for (let originalRoomImage of this.originalRoomImages) {
    imagesArray.push(originalRoomImage.imageName);
  }
}

if(resizedFiles != null){
  for (let resizedFile of resizedFiles) {
    imagesArray.push(resizedFile.name);
    }
}


room.imageUrls = imagesArray;


this.roomsCollectionRef = this.afs.collection<Room>('Houses/' + localStorage.getItem(getHousePreferenceKey(this.data.uid)) + '/Rooms');

if(this.data.editing){
  this.roomsCollectionRef.doc(this.data.room.id).update({...room}).then(response1 => {
    this.dialogRef.close();
  })

  
}else{

this.roomsCollectionRef.add({...room}).then(response1 => {
  this.dialogRef.close();
  this.navigateToRoom(response1.id)
})

}
}

navigateToRoom(roomId) {
this.router.navigate(['/room', {houseId: this.data.houseId, id: roomId }]);
}




onSelect(event) {
  if(this.hasAnImageAlreadySelected()){
    this.originalRoomImages = [];
    this.previewImages = []
  }

    this.ng2ImgMaxService.compressImage(event.addedFiles[0], .25, true).subscribe((result)=>{ //todo sizing

      const imageFile = new File([result], getFormattedTime());
      const reader: FileReader = new FileReader();
      reader.readAsDataURL(imageFile);
      reader.onload = () => {
        this.previewImages.push(new PreviewImage(imageFile, imageFile.name, reader.result));
      };

    },
    error => {
      console.log('Error compressing image', error);
    });

}

hasAnImageAlreadySelected(){
  return this.originalRoomImages.length>0 || this.previewImages.length>0;
}



}


